export default class Menu {
  constructor() {
    this.this = '#main-menu'
    this.$this = $(this.this)
    this.$mainMenu = $('#main-menu').find('.main-menu__ul')
    this.elementItem = '.js-hamburger, html, #main-menu, #header'
    this.$header = $('#header, #main-menu-mobile')
    this.$hamburgerMenu = $('.js-hamburger')
    this.li = this.$this.find('.main-menu__ul >li>a')
    this.isOpenMenu = 'is-open-menu'
    this.isOpenMenuChild = 'open-menu-child'
    this.isOpenChild = 'is-open-child'
    this.$linksInMenu = $('.header a:not(.skip-link), .header button')
    this.$firstLinkInMenu = this.$linksInMenu.first()
    this.$lastLinkInMenu = this.$linksInMenu.last()
    this.$skipLink = $('.header a.skip-link')
    this.ariaExpanded = 'aria-expanded'
  }

  init() {
    if (this.$this.length) {
      this.openMainMenu()
      this.clickOutSite()
      this.toggleTabIndexSkipLink()
    }
  }

  openMainMenu() {
    this.$header.on('click', '.js-hamburger', (e) => {
      const ele = e.currentTarget

      if ($(ele).hasClass(this.isOpenMenu)) {
        $(this.elementItem).removeClass(this.isOpenMenu)
        this.$hamburgerMenu.find('.sr-only').text('Open menu')
        this.$hamburgerMenu.attr(this.ariaExpanded, false)
        this.$skipLink.removeAttr('tabindex')
      } else {
        $(this.elementItem).addClass(this.isOpenMenu)
        this.$hamburgerMenu.find('.sr-only').text('Close menu')
        this.$hamburgerMenu.attr(this.ariaExpanded, true)
        this.bindEventFocusOnMenu()
      }
    })
  }

  toggleTabIndexSkipLink() {
    $(window).on('resize', () => {
      if ($(window).outerWidth() < 992) {
        this.$skipLink.removeAttr('tabindex')
      }
    })
  }

  bindEventFocusOnMenu() {
    this.$skipLink.attr('tabindex', -1)
    this.$firstLinkInMenu.get(0).addEventListener('keydown', this.shiftTabFocusToLast.bind(this))
    this.$lastLinkInMenu.get(0).addEventListener('keydown', this.tabFocusToFirst.bind(this))
  }

  tabFocusToFirst(e) {
    if (e.which === 9 && $(window).outerWidth() < 992 && !e.shiftKey) {
      e.preventDefault()
      this.$firstLinkInMenu.focus()
    }
  }

  shiftTabFocusToLast(e) {
    if (e.which === 9 && $(window).outerWidth() < 992 && e.shiftKey) {
      e.preventDefault()
      this.$lastLinkInMenu.focus()
    }
  }

  clickOutSite() {
    $(document).on('click', (event) => {
      if (!$(event.target).closest('#header.is-open-menu').length) {
        $(this.elementItem).removeClass(this.isOpenMenu)
      }
    })
  }

  closeAllSubmenu() {
    const $liHover = this.$this.find('.main-menu__ul>li.hovering')
    $liHover.removeClass('hovering')
    if ($liHover.hasClass('has-sub')) {
      $liHover.find('> a').attr(this.ariaExpanded, false)
    }
  }

}

new Menu().init()
