export default class LoadJsDelay {
  constructor(callback = null) {
    this.action = ['keydown', 'mousedown', 'mousemove', 'touchmove', 'touchstart', 'touchend', 'wheel']
    this.callDelayB = false
    this.callback = callback;
  }

  delayCss() {
    $('.lazy-event').removeClass('lazy-event')
    $('[data-lazycss="true"]').each((i, e) => {
      $(e).replaceWith(`<link rel="stylesheet" id="${$(e).attr('id')}"  href="${$(e).data('href')}" media="screen">`)
    })
    if (typeof window.lazyLoadBg !== 'undefined') {
      window.lazyLoadBg.init()
    }
  }

  delayJs() {
    const $delayBeforeJs = $('[data-lazyjs="true"]')
    $delayBeforeJs.each((i, e) => {
      const scriptUrl = $(e).data('src')
      $(e).removeAttr('data-lazyjs data-src')
      $(e).attr('src', scriptUrl)
    })
    if (this.callback) {
      this.callback();
    }
    if (typeof window.loadActionSelectC8 !== 'undefined') {
      window.loadActionSelectC8()
    }
  }

  callDelay() {
    if (this.callDelayB) return
    this.callDelayB = true
    this.delayCss()
    this.delayJs()
  }

  addEventListeners() {
    this.action.forEach((ac) => {
      document.addEventListener(ac, () => {
        this.callDelay()
      })
    })
  }

  init() {
    this.addEventListeners()
    if ($(window).scrollTop() > 0) {
      this.callDelay()
    }
  }
}
