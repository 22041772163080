function render(el, cb) {
  if (!el) {
    throw new Error('You must define a dom object.');
  }

  if (typeof el !== 'object' || Array.isArray(el)) {
    throw new TypeError('This method requires a dom object to be passed in.');
  }

  if (!cb) {
    throw new Error('You must define a callback method.');
  }

  if (typeof cb !== 'function') {
    throw new TypeError('You must provide a Function.');
  }

  el.forEach(cb);
}

export default render;
