import LoadJsDelay from './components/LoadJsDelay';
import create from './libs/core';
import modules from './modules';

// components
import './components/BrowserDetection';
import './components/CheckDevice';
import './components/LazyLoadImage'
import './components/UserAgent';
import './components/AnimationScrollPage'
import './components/BgVideoOptions'
import './components/header/Header'
import './components/header/Menu'
//ADA support ADA
// import './components/Popup'
import './components/header/ADAMenu'
import './components/ADA'

if ($('body.home').length) {
  new LoadJsDelay(() => {
    create(modules);
  }).init();
}

document.addEventListener(
  'DOMContentLoaded',
  async () => {
    if ($('body.home').length) return;
    create(modules);
    new LoadJsDelay().init();

  },
  false,
);
// Hot reload for start dev.
if (import.meta.hot) {
  console.log('Updated vite');
}
