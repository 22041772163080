// import React from 'react';
// import { createRoot } from 'react-dom/client';
// import { Provider } from 'react-redux';

const modules = [
  // React Modules
  // {
  //   name: 'ShopAccessories',
  //   loader: () => import('./modules/react/shop-accessories/ShopAccessoriesRoot.js'),
  //   async render(Root, el) {
  //     const store = await import('./modules/react/shop-accessories/store/index.js');
  //     createRoot(el[0]).render(<Provider store={store.store}><Root rootElement={el[0]} /></Provider>);
  //   },
  // },
  // JS Modules
  {
    name: 'Typography',
    loader: () => import('./components/Typography'),
  },
  {
    name: 'FormAnimation',
    loader: () => import('./components/FormAnimation'),
  },
  {
    name: 'SelectC8',
    loader: () => import('./components/SelectC8'),
  },
  {
    name: 'TabList',
    loader: () => import('./modules/TabList'),
  },
  {
    name: 'Popup',
    loader: () => import('./components/Popup'),
  },
  {
    name: 'ModTimelineHistory',
    loader: () => import('./modules/ModTimelineHistory'),
  },
];

export default [...modules];
