export default class BgVideoOptions {
  constructor(el) {
    this.$el = $(el);
    this.playerType = ''

    this.dom = {
      $playPauseBtn: this.$el.find('.play-pause-btn'),
      $muteUnmuteBtn: this.$el.find('.mute-unmute-btn'),
      $player: this.$el.find('.background-video-inner'),
    };

    this.elementClasses = {
      dashIcon: '.dashicons',
    }

    this.classes = {
      dashIconPlay: 'dashicons-controls-play',
      dashIconPause: 'dashicons-controls-pause',
      dashIconMute: 'dashicons-controls-volumeon',
      dashIconUnmute: 'dashicons-controls-volumeoff',
    };
  }

  init() {
    this.getPlayerType();
    this.initPlayerStatus();
    this.addEventListener();

  }

  getPlayerType() {
    const playerTag = this.dom.$player.prop('tagName').toLowerCase();

    if (playerTag === 'video') {
      this.playerType = 'video';
    } else {
      this.playerType = 'iframe';
    }
  }

  initPlayerStatus() {
    if (this.playerType === 'video') {
      this.getHTMLPlayerStatus(this.playerType)
    } else {
      this.getIframePlayerStatus(this.playerType)
    }
  }

  getHTMLPlayerStatus() {
    if (this.dom.$player[0].paused) {
      this.dom.$playPauseBtn
        .find(this.elementClasses.dashIcon)
        .removeClass(this.classes.dashIconPause)
        .addClass(this.classes.dashIconPlay);
    }
  }

  getIframePlayerStatus() {

  }

  addEventListener() {
    this.dom.$playPauseBtn.on('click', (e) => {
      const icon = $(e.currentTarget).find(this.elementClasses.dashIcon);
      if (icon.hasClass(this.classes.dashIconPause)) {
        this.handleVideo('pause');
        icon.removeClass(this.classes.dashIconPause).addClass(this.classes.dashIconPlay);
      } else if (icon.hasClass(this.classes.dashIconPlay)) {
        this.handleVideo('play');
        icon.removeClass(this.classes.dashIconPlay).addClass(this.classes.dashIconPause);
      }
    });
    this.dom.$muteUnmuteBtn.on('click', (e) => {
      const icon = $(e.currentTarget).find(this.elementClasses.dashIcon);
      if (icon.hasClass(this.classes.dashIconMute)) {
        this.handleVideo('mute');
        icon.removeClass(this.classes.dashIconMute).addClass(this.classes.dashIconUnmute);
      } else if (icon.hasClass(this.classes.dashIconUnmute)) {
        this.handleVideo('unmute');
        icon.removeClass(this.classes.dashIconUnmute).addClass(this.classes.dashIconMute);
      }
    });
  }

  handleVideo(action) {
    if (this.playerType === 'video') {
      this.handleHtml5VideoAction(action)
    } else {
      this.handleIframeVIdeoAction(action)
    }
  }

  handleIframeVIdeoAction(action) {
    const player = this.dom.$player.get(0);
    const playerSrc = this.dom.$player.prop('src');
    const vimeoMatch = playerSrc.match(/vimeo\.com\/(\d+)/);
    const youtubeMatch = playerSrc.match(
      /(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/watch\?.+&v=))([^&#\n]+)/,
    );

    const Youtube = 'https://www.youtube.com';
    const Vimeo = 'https://player.vimeo.com';

    if (youtubeMatch) {
      const commandMap = {
        pause: 'pauseVideo',
        play: 'playVideo',
        mute: 'mute',
        unmute: 'unMute',
      };
      player.contentWindow.postMessage(`{"event":"command","func":"${commandMap[action]}","args":""}`, Youtube);
    } else if (vimeoMatch) {
      const methodMap = {
        pause: 'pause',
        play: 'play',
        mute: 'mute',
        unmute: 'unmute',
      };
      player.contentWindow.postMessage(`{"method":"${methodMap[action]}"}`, Vimeo);
    }
  }

  handleHtml5VideoAction(action) {
    const player = this.dom.$player.get(0);
    const actions = new Map([
      ['play', () => player.play()],
      ['pause', () => player.pause()],
      ['mute', () => { player.muted = true }],
      ['unmute', () => { player.muted = false; }],
    ]);

    if (actions.has(action)) {
      actions.get(action)();
    }
  }
}

const $BgVideo = $('.background-video');
if ($BgVideo.length) {
  $BgVideo.each((_index, element) => {
    new BgVideoOptions(element).init();
  });
}
