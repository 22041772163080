// @flow
import importModule from './importModule';
import render from './render';

export default function create(organisms) {
  return Promise.all(
    organisms.map(async (organism) => {
      try {
        const data = await importModule(
          organism.name,
          organism.loader,
          organism.styles
        );

        if (!data) return;

        const { module, el } = data;

        if (organism.render && typeof organism.render === 'function') {
          organism.render(module, el);
          return;
        }

        render(el, ($target) => {
          new module($target, organism.props);
        });
      } catch (error) {
        console.error(`Failed to import module "${organism.name}":`, error);
      }
    })
  );
}

// export { render, importModule };
