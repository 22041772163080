export default function importModule(name, loader, styles) {
  const el = document.querySelectorAll(`[data-module="${name}"]`);

  if (el.length === 0) {
    return Promise.resolve();
  }

  if (styles) {
    styles()
  }

  if (loader) {
    return loader()
      .then((module) => {
        return {
          module: module.default,
          el,
        };
      })
  }
}
