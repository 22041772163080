export default class AnimationScrollPage {
  constructor() {
    this.$el = $('.animation')
    this.winH = window.innerHeight
    this.winW = window.innerWidth
    this.offset = window.innerHeight
    this.WindowScrollTop = null
    this.topCurrent = null
    this.addClassAni = 'set-animation'
  }

  init() {
    this.animationEle()
    this.animationParallax()
    $(window).on('scroll resize', () => {
      this.animationEle()
      this.animationParallax()
    })
  }

  formatNumber(text, min, max) {
    return text.toLocaleString('en-US', {
      minimumFractionDigits: min,
      maximumFractionDigits: max,
    })
  }

  animationNumber($el) {
    const $parent = $el.parents('.mod-metric')
    if (!$parent.hasClass('no-animation')) {
      const numberCount = parseFloat($el.attr('data-numbercounter'))
      const durationAnima = $parent.attr('data-duration') ? parseFloat($parent.attr('data-duration')) : 4000
      $el.addClass('counted')
      $el.prop('Counter', 0).animate({
        Counter: numberCount,
      }, {
        duration: durationAnima,
        easing: 'swing',
        step: (now) => {
          $el.text(this.formatNumber(Math.floor(now), 0, 2))
        },
        complete: () => {
          $el.text(this.formatNumber(numberCount, 0, 2))
        },
      })
    } else {
      $el.text($el.attr('data-numbercounter'))
    }
  }

  animationEle() {
    if (window.innerWidth < 1200) return
    this.offset = this.winH
    if ($('.wrapper').hasClass('has-animation')) {
      this.WindowScrollTop = $(window).scrollTop()
      this.$allAnima = this.$el.find('[class*="anima-"]')
      this.$allAnima.each((_index, ele) => {
        const $elm = $(ele)
        if ($elm.hasClass(this.addClassAni)) {
          return true
        }
        this.topCurrent = $elm.offset().top
        if (this.WindowScrollTop > this.topCurrent - this.offset) {
          $elm.addClass(this.addClassAni)
          if ($elm.hasClass('number-counter') && !$elm.hasClass('counted')) {
            this.animationNumber($elm)
          }
        }
        return true
      })
    }
  }

  animationParallax() {
    const $parallax = $('.image-content__grid')
    if (!$parallax.length) return
    if (window.innerWidth < 1200) {
      $parallax.find('.image-content__box, .ellipse-svg, .image-content__image img').css({
        'transform': '',
        'transition': '',
      })
      return
    }
    const windowHeight = $(window).height()
    const scrolled = $(window).scrollTop()

    $parallax.each((_index, ele) => {
      const $elm = $(ele)
      const $img = $elm.find('.image-content__image img')
      const $content = $elm.find('.image-content__box')
      const $svg = $elm.find('.ellipse-svg')
      const elementTop = $elm.offset().top
      const elementCenter = elementTop + ($elm.height() / 2)
      const windowCenter = scrolled + (windowHeight / 2)
      const distanceFromCenter = elementCenter - windowCenter

      const moveRatio = distanceFromCenter / windowHeight

      $img.css({
        'transform': `translateY(${moveRatio * 33}px)`,
        'transition': 'transform 0.5s ease-out',
      })

      $content.css({
        'transform': `translateY(${moveRatio * 66}px)`,
        'transition': 'transform 0.5s ease-out',
      })

      $svg.css({
        'transform': `translateY(${moveRatio * 100}px)`,
        'transition': 'transform 0.5s ease-out',
      })
    })
  }

}

new AnimationScrollPage().init()
