export default class Header {
  constructor() {
    this.$header = $('#header')
    this.$search = $('.header__search')
    this.$searchInput = this.$search.find('input')
    this.$clearSearch = this.$search.find('.js-clear-search')
    this.$openSearch = $('.js-open-search')

    this.numberScroll = 0
    this.scrollTop = 0
    this.classes = {
      pin: 'pin-header',
      searchActive: 'search-active',
      menuOpen: 'is-open-menu',
      active: 'active',
      hidden: 'hidden',
    }

    this.handleScroll = this.debounce(this.settingPin.bind(this), 0)
  }

  init() {
    if (this.$header.length) {
      this.scrollPinHeader()
      this.controlSearchHeader()
    }
  }

  debounce(func, wait) {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout)
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }

  controlSearchHeader() {
    this.$openSearch.on('click', () => {
      if (this.$header.hasClass(this.classes.menuOpen)) {
        this.$header.find('.js-hamburger').trigger('click')
      }
      this.$search.toggleClass(this.classes.active)
      this.$header.toggleClass(this.classes.searchActive)

      if (this.$search.hasClass(this.classes.active)) {
        this.$header.find('.form-control').focus()
      }
    })

    $(document).on('click.header', (e) => {
      const $target = $(e.target)
      if (!$target.closest('.header__search, .js-open-search').length && !$target.is('.open-header')) {
        this.$search.removeClass(this.classes.active)
        this.$header.removeClass(this.classes.searchActive)
      }
    })

    this.$searchInput.on('keyup', (e) => {
      this.$clearSearch.toggleClass(this.classes.hidden, !e.target.value)
    })

    this.$clearSearch.on('click', (e) => {
      e.preventDefault()
      this.$searchInput.val('')
      this.$clearSearch.addClass(this.classes.hidden)
    })
  }

  scrollPinHeader() {
    this.settingPin()
    $(window).on('scroll.header resize.header orientationchange.header', this.handleScroll)
  }

  settingPin() {
    this.scrollTop = $(window).scrollTop()
    this.$header.toggleClass(this.classes.pin, this.scrollTop > this.numberScroll)
  }

  destroy() {
    $(window).off('.header')
    $(document).off('.header')
    this.$openSearch.off()
    this.$searchInput.off()
    this.$clearSearch.off()
  }
}

const header = new Header()
header.init()
