export default class UserAgent {
  constructor() {
    this.$html = $('html')
    this.$shareBtn = $('.copy-share')
  }

  init() {
    this.setResponsiveTable()
    this.removeNoscript()
    $('.dot-block').addClass('set-done')
    this.handleEvents()
  }

  removeNoscript() {
    $('#notify').remove()
  }

  handleEvents() {
    this.$shareBtn.on('click', (e) => {
      e.preventDefault()
      this.handleCopyLink()
      const $currentTarget = $(e.currentTarget)
      $currentTarget.addClass('copied')
      setTimeout(() => {
        $currentTarget.removeClass('copied')
      }, 2000)
    })
  }

  handleCopyLink() {
    const currentUrl = window.location.href
    const textarea = document.createElement('textarea')
    textarea.value = currentUrl
    textarea.style.position = 'fixed'
    textarea.style.opacity = '0'
    document.body.appendChild(textarea)
    textarea.select()

    // Use the Clipboard API
    navigator.clipboard.writeText(textarea.value)
    document.body.removeChild(textarea)
  }

  setResponsiveTable() {
    const $tables = $('.mod-content-post table');
    if ($tables.length === 0) return;
    $tables.each((_i, e) => {
      $(e).wrap('<div class="table-responsive"></div>');
    });
  }
}

new UserAgent().init()